<template>
    <modal ref="modalSubirArchivo" titulo="Subir archivo" no-aceptar :cargado-modal="loading" adicional="Cargar" @adicional="cargar">
        <p class="mx-0 f-14 px-4 text-general">Por favor seleccione la columna contra la cual desea comparar el archivo.</p>
        <div class="row mx-0 px-4 my-2" :class="{'disabled-div': remplazar}">
            <el-radio v-model="tipo" :label="1">
                BarCode
            </el-radio>
            <el-radio v-model="tipo" :label="2">
                SKU
            </el-radio>
        </div>
        <div class="row mx-0 align-items-center py-3 px-4">
            <el-upload
            ref="upload"
            action="#"
            :on-change="changeFile"
            :on-remove="removeFile"
            :auto-upload="false"
            :multiple="false"
            :limit="1"
            accept=".xlsx"
            >
                <div class="bg-general2 text-white br-8 px-3 py-1 f-16 cr-pointer shadow">
                    Seleccionar archivo
                </div>
            </el-upload>
        </div>
        <p class="mx-0 f-14 px-4 text-general">Solo se aceptan archivos con extención ( .xlsx )</p>
    </modal>
</template>
<script>
import Productos from "~/services/productos/productos_stocks";

export default {
    data(){
        return {
            loading:false,
            file:null,
            remplazar:false,
            tipo:1
        }
    },
    computed:{
        id_cedis(){
            return Number(this.$route.params.id_cedis)
        },
        id_oc(){
            let id = Number(this.$route.params.id_oc)
            if(id) return id
            else return false
        }
    },
    methods: {
        toggle(){
            this.remplazar = false
            this.$refs.upload.clearFiles()
            this.$refs.modalSubirArchivo.toggle();
        },
        editar(origen){
            this.remplazar = true
            this.tipo = origen
            this.$refs.upload.clearFiles()
            this.$refs.modalSubirArchivo.toggle();
        },
        changeFile(file){
            //console.log(file);
            this.file = file.raw
        },
        removeFile(){
            this.file = null
        },
        async cargar(){
            if(this.remplazar){
                this.remplazarImporacion()
                return
            }

            try {
                if(this.file === null) return
                let model = {
                    id_cedis:this.id_cedis,
                    id_oc_ingreso:this.id_oc,
                    origen:this.tipo,
                    file:this.file
                }
                model = this.crear_formData(model)
                this.loading =  true
                const {data} = await Productos.importarStock(model)
                this.$refs.modalSubirArchivo.toggle();

                this.$router.push({
                    name: 'admin.cedis.importar-inventario',
                    params:{
                        id_cedis:this.id_cedis,
                        id_importacion:data.id
                    }
                })
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading =  false
            }

        },
        async remplazarImporacion(){
            try {
                if(this.file === null) return
                let model = {
                    id_cedis:this.id_cedis,
                    id_oc_ingreso:this.id_oc,
                    id_importacion: this.$route.params.id_importacion,
                    origen:this.tipo,
                    file:this.file
                }
                model = this.crear_formData(model)
                this.loading =  true
                const {data} = await Productos.remplazarStock(model)
                await this.$router.push({
                    params:{
                        id_cedis:this.id_cedis,
                        id_importacion:data.id
                    }
                })
                this.$emit('update')
                this.$refs.modalSubirArchivo.toggle();

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading =  false
            }
        }
    }
}
</script>
